import { types } from '../action/defineAction';

export function defineReducer(state = {
  food_type: [],
}, { type, payload }) {
  switch (type) {
    case types.DEF_CATEGORY_BY_TYPE:
      return {
        ...state,
        [payload.type]: payload.result,
      };
    default: return state;
  }
}

export default {
  defineReducer,
};