
import moment from 'moment';

export function sortDistance(locations) {
  return locations
    .sort((a, b) => {
      const dA = a.distance && a.distance.unit === "km" ? a.distance.number * 1000 : (a.distance ? a.distance.number : 1)
      const dB = b.distance && b.distance.unit === "km" ? b.distance.number * 1000 : (b.distance ? b.distance.number : 1)
      return dA - dB;
    });
};

export function addAndRemove(list, id) {
  let newSelectTime = [];
  // already exist
  if (list.includes(id)) {
    newSelectTime = [
      ...list.filter(st => st !== id),
    ];
    // not exist
  } else {
    newSelectTime = [
      ...list,
      id,
    ];
  }
  return newSelectTime;
}

export const displayDateFormat = (d) => moment(d).format('D MMM');

export function distance(lat1, lon1, lat2, lon2) {
  var R = 6371; // km (change this constant to get miles)
  var dLat = (lat2 - lat1) * Math.PI / 180;
  var dLon = (lon2 - lon1) * Math.PI / 180;
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  // if (d > 1)
  return {
    number: Math.round(d),
    unit: "km"
  };
  // else if (d <= 1) return {
  //   number: Math.round(d * 1000),
  //   unit: "m"
  // };
  // return null;
}

export async function sequenceAsync(array, action = () => { }, after = () => { }) {
  await asyncForEach(array, async (item, index) => action(item, index));
  after();
}

export function getEnv() {
  const isProd = process.env.REACT_APP_BRANCH.includes('master');
  const isRelease = process.env.REACT_APP_BRANCH.includes('release');
  const label = process.env.REACT_APP_BRANCH || 'local (no branch)';
  let url = '';
  let adminUrl = '';
  if(isProd) {
    adminUrl = 'https://ft-admin-prod.web.app';
    url = 'https://asia-northeast1-foodtruck-react-prod.cloudfunctions.net';
  } else if(isRelease) {
    adminUrl = 'https://ft-admin-staging.web.app';
    url = 'https://asia-northeast1-foodtruck-react-prod.cloudfunctions.net';
  } else {
    adminUrl = 'https://ft-admin.web.app';
    url = 'https://asia-northeast1-foodtruck-react.cloudfunctions.net';
  }
  return {
    isProd,
    label,
    url,
    adminUrl,
  };
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function buildHash(values) {
  return values.sort(cmp).join('')
}

function cmp(a, b) {
  const newA = a.substr(0, 3);
  const newB = b.substr(0, 3);

  if (newA === newB) {
      return 0;
  }

  return (newA < newB) ? -1 : 1;
}

export default {
  addAndRemove,
  sequenceAsync,
}