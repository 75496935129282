import { types } from '../action/sharedAction';

export function sharedReducer(state = {
  title: 'ค้นหาพื้นที่ขาย',
}, { type, payload }) {
  switch (type) {
    case types.SHARED_SET_TITLE:
      return {
        ...state,
        title: payload.result,
      };
    default: return state;
  }
}

export default {
  sharedReducer,
};