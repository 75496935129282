import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { defineReducer } from './defineReducer';
import { eventReducer } from './eventReducer';
import { invoiceReducer } from './invoiceReducer';
import { locationReducer } from './locationReducer';
import { maxCardReducer } from './maxCardReducer';
import { orgReducer } from './orgReducer';
import { profileReducer } from './profileReducer';
import { sharedReducer } from './sharedReducer';
import { voucherReducer } from './voucherReducer';

export default combineReducers({
  auth: authReducer,
  profile: profileReducer,
  define: defineReducer,
  location: locationReducer,
  shared: sharedReducer,
  invoice: invoiceReducer,
  maxCard: maxCardReducer,
  voucher: voucherReducer,
  org: orgReducer,
  event: eventReducer,
});
