// Location
export const LOCATION_ACTIVE = 'active';
export const LOCATION_INACTIVE = 'inactive';
// Booking
export const BOOK_PAID_STATUS = 'paid';
export const BOOK_UNPAID_STATUS = 'unpaid';
export const BOOK_CANCEL_STATUS = 'cancel';
// Truck
export const TRUCK_PRE_REGIS_STATUS = 'pre_regis';
export const TRUCK_WAIT_APPROVE_STATUS = 'wait_approve';
export const TRUCK_ACTIVE_STATUS = 'active';
export const TRUCK_PENDING_STATUS = 'pending';
export const TRUCK_DENY_STATUS = 'deny';
// Time slot
export const TIMESLOT_ACTIVE_STATUS = 'active';
export const TIMESLOT_INACTIVE_STATUS = 'inactive';
// Slot
export const SLOT_ACTIVE_STATUS = 'active';
export const SLOT_INACTIVE_STATUS = 'inactive';
// MAXCARD
export const MAXCARD_ID_SEARCH_TYPE = 'getProfileByMaxCard';
export const MAXCARD_TEL_SEARCH_TYPE = 'getProfileByMobile';
export const MAXCARD_ACTIVE = 'I';
export const PRODUCT_CODE_PROD = '1801001000001';
export const TEST_CARDNUBER = '1131109783';
// TICKET
export const TICKET_AVAILABLE = 'available';
export const TICKET_USED = 'used';
export const TICKET_PAID = 'paid';
// ORG
export const ORG_ACTIVE_STATUS = 'active';
export const ORG_INACTIVE_STATUS = 'inactive';
// EVENT
export const EVENT_ACTIVE_STATUS = 'active';
export const EVENT_ATTEND = 'attend';
export const EVENT_WAIT_ORG_CONFIRM = 'wait_org_con';
export const EVENT_ORG_CON = 'org_con';
export const EVENT_SUBMITED = 'submited';
// TITLE
export const AT_HOME = 'at home';
export const PT_PARK = 'pt station';
// lo type
export const getLocationByType = {
  pt: 'ปั้ม',
  market: 'ตลาด',
};

export default {};