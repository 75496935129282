import { types } from '../action/voucherAction';

export function voucherReducer(state = {
  myTicket: [],
  allVoucher: [],
  allTicket: [],
  selectedTicket: [],
  usedTicket: [],
}, { type, payload }) {
  switch (type) {
    case types.VOUCHER_TICKET_BY_CODE:
      return {
        ...state,
        selectedTicket: payload.result,
      };
    case types.VOUCHER_MY_TICKET_GET:
      return {
        ...state,
        myTicket: payload.result,
      };
    case types.VOUCHER_ALL_TICKET_GET:
      return {
        ...state,
        allTicket: payload.result,
      };
    case types.VOUCHER_ALL_GET:
      return {
        ...state,
        allVoucher: payload.result,
      };
    case types.VOUCHER_USED_TICKET_GET:
      return {
        ...state,
        usedTicket: payload.result,
      }
    default: return state;
  }
}

export default {
  voucherReducer,
};