
import _ from 'lodash';
import moment from 'moment-timezone';
import { ORG_ACTIVE_STATUS } from '../constant';
import fire from '../fire';

const db = fire.firestore();
const functions = fire.functions('asia-northeast1');
const createUser = functions.httpsCallable('createUser');

export const types = {
  ORG_GET: 'ORG_GET',
};

export function updateOrg(id, field, data) {
  return db.collection("profile")
    .doc(id)
    .update({
      [field]: data,
    });
}

export function getAllOrganization() {
  return dispatch => {
    db.collection("profile").where('type', '==', 'org')
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.ORG_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.ORG_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export async function createOrg(profile) {
  const mProfile = _.pickBy(profile, p => p !== undefined);
  const email = profile.email.toLowerCase();
  return createUser({
    email,
    emailVerified: false,
    password: mProfile.password,
  })
    .then(({ data: { uid } }) => {
      return db.collection("profile").doc(uid).set({
        ...mProfile,
        email,
        type: 'org',
        uid,
        timestamp: moment().tz('Asia/Bangkok').valueOf(),
        regis_date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
        status: ORG_ACTIVE_STATUS,
      });
    })
}