
import fire from '../fire';

export const types = {
  DEF_CATEGORY_BY_TYPE: 'DEF_CATEGORY_BY_TYPE',
};

export function getCatByType(type) {
  return dispatch => {
    fire.firestore().collection("category").where('type', '==', type)
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.DEF_CATEGORY_BY_TYPE,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data()})),
              type,
            }
          });
        } else {
          dispatch({
            type: types.DEF_CATEGORY_BY_TYPE,
            payload: {
              result: [],
              type,
            },
          });
        }
      });
  }
}