
import fire from '../fire';

export const types = {
  AUTH_FIREBASE: 'AUTH_FIREBASE',
};

export function authentication() {
  return dispatch => {
    fire.auth().onAuthStateChanged(authUser => {
      dispatch({
        type: types.AUTH_FIREBASE,
        payload: {
          result: authUser ? authUser : null,
        },
      });
    });
  }
}

export function signout() {
  return fire.auth().signOut();
}

export function signin(email, password) {
  return fire.auth().signInWithEmailAndPassword(email.toLowerCase(), password)
}

export function signinCustomToken(token) {
  return fire.auth().signInWithCustomToken(token)
}