import { types } from '../action/authAction';

export function authReducer(state = {
  authUser: undefined,
}, { type, payload }) {
  switch (type) {
    case types.AUTH_FIREBASE:
      return {
        ...state,
        authUser: payload.result,
      };
    default: return state;
  }
}

export default {
  authReducer,
};