
import moment from 'moment-timezone';
// import axios from 'axios';
import fire from '../fire';
import { getEnv } from '../util/helper';

const db = fire.firestore();

export const types = {
  VOUCHER_TICKET_BY_CODE: 'VOUCHER_TICKET_BY_CODE',
  VOUCHER_MY_TICKET_GET: 'VOUCHER_MY_TICKET_GET',
  VOUCHER_ALL_TICKET_GET: 'VOUCHER_ALL_TICKET_GET',
  VOUCHER_ALL_GET: 'VOUCHER_ALL_GET',
  VOUCHER_USED_TICKET_GET: 'VOUCHER_USED_TICKET_GET',
};

export function exportTicket(redeemDate) {
  const { url } = getEnv();
  return window.open(`${url}/reportTicket?redeemDate=${redeemDate}`)
  // return axios.get(
  //   `${url}/reportTicket?redeemDate=${redeemDate}`, { query: { redeemDate } }, {
  //     headers: { 'Access-Control-Allow-Origin': '*' }
  //   }
  // )
}

export function updateVoucher(id, field, data) {
  return db.collection("voucher")
    .doc(id)
    .update({
      [field]: data,
    });
}

export function updateTicket(id, field, data) {
  return db.collection("ticket")
    .doc(id)
    .update({
      [field]: data,
    });
}

export function createVoucher(detail) {
  return db.collection("voucher")
    .add({
      ...detail,
      create_date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    });
}

export function createTicket(detail) {
  return db.collection("ticket")
    .add({
      ...detail
    });
}

export function getTicketByCode(code, callback = () => {}) {
  return dispatch => {
    db.collection("ticket")
      .where('code', '==', code)
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.VOUCHER_TICKET_BY_CODE,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
          callback();
        } else {
          dispatch({
            type: types.VOUCHER_TICKET_BY_CODE,
            payload: {
              result: [],
            },
          });
          callback();
        }
      });

  }
}

export function getUsedTicket() {
  return dispatch => {
    db.collection("ticket")
      .where('status', '==', 'used')
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.VOUCHER_USED_TICKET_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.VOUCHER_USED_TICKET_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getAllVoucher() {
  return dispatch => {
    db.collection("voucher")
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.VOUCHER_ALL_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.VOUCHER_ALL_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getAllTicket() {
  return dispatch => {
    db.collection("ticket")
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.VOUCHER_ALL_TICKET_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.VOUCHER_ALL_TICKET_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getMyTicket() {
  return (dispatch, getState) => {
    const { auth: { authUser: { uid } } } = getState();
    db.collection("ticket")
      .where('redeemShopId', '==', uid)
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.VOUCHER_MY_TICKET_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            },
          });
        } else {
          dispatch({
            type: types.VOUCHER_MY_TICKET_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}