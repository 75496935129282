import { types } from '../action/invoiceAction';

export function invoiceReducer(state = {
  myInvoice: [],
  allInvoice: [],
  invoiceRange: [],
}, { type, payload }) {
  switch (type) {
    case types.INVOICE_BOOK_DATE_RANGE:
      return {
        ...state,
        invoiceRange: payload.result,
      };
    case types.INVOICE_MY_GET:
      return {
        ...state,
        myInvoice: payload.result,
      };
    case types.INVOICE_ALL_GET:
      return {
        ...state,
        allInvoice: payload.result,
      };
    default: return state;
  }
}

export default {
  invoiceReducer,
};