import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { authentication } from '../../action/authAction';
import { getEnv } from '../../util/helper';
import Loading from '../Loading';
import styles from './App.module.scss';

const CentralSignIn = (process.env.REACT_APP_PROJ === 'org' || process.env.REACT_APP_PROJ === 'admin') &&
  Loadable({
    loader: () => import('../CentralSignIn'),
    loading: () => <Loading />,
  });

const BaseMain = process.env.REACT_APP_PROJ === 'main' && Loadable({
  loader: () => import('../BaseMain'),
  loading: () => <Loading />,
});

const BaseAdmin = process.env.REACT_APP_PROJ === 'admin' && Loadable({
  loader: () => import('../BaseAdmin'),
  loading: () => <Loading />,
});

const BaseTruck = process.env.REACT_APP_PROJ === 'truck' && Loadable({
  loader: () => import('../BaseTruck'),
  loading: () => <Loading />,
});

const BaseOrg = process.env.REACT_APP_PROJ === 'org' && Loadable({
  loader: () => import('../BaseOrg'),
  loading: () => <Loading />,
});

// const OrgSignIn = process.env.REACT_APP_PROJ === 'org' && Loadable({
//   loader: () => import('../OrgSignIn'),
//   loading: () => <Loading />,
// });


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.authentication = this.authentication.bind(this);
    this.authentication();
  }

  componentDidMount() {
    this.authentication();
  }

  componentWillUnmount() {
    this.authentication();
  }

  authentication() {
    const { authenticationAct } = this.props;
    authenticationAct();
  }

  render() {
    const { authUser } = this.props;
    const { isProd, label } = getEnv();
    return <div>
      {!isProd && <span className={styles.notMasterLabel}>{`${label} (${process.env.REACT_APP_PROJ})`}</span>}
      {process.env.REACT_APP_PROJ === 'admin' && <Switch>
        {authUser === undefined && <Route component={Loading} />}
        {authUser && <Route component={BaseAdmin} />}
        <Route component={CentralSignIn} />
      </Switch>}
      {process.env.REACT_APP_PROJ === 'truck' && <Switch>
        {authUser === undefined && <Route component={Loading} />}
        <Route component={BaseTruck} />
      </Switch>}
      {process.env.REACT_APP_PROJ === 'org' && <Switch>
        {authUser === undefined && <Route component={Loading} />}
        {authUser && <Route component={BaseOrg} />}
        <Route component={CentralSignIn} />
      </Switch>}
      {process.env.REACT_APP_PROJ === 'main' && <Switch>
        <Route component={BaseMain} />
      </Switch>}
    </div>
  }
}

function mapStateToProps(state) {
  return {
    authUser: state.auth.authUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    authenticationAct: authentication,
  }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(App),
);
