
import fire from '../fire';

const db = fire.firestore();
const functions = fire.functions('asia-northeast1');
const confirmRegis = functions.httpsCallable('confirmRegis');

export const types = {
  PROFILE_GET: 'PROFILE_GET',
  PROFILE_SIGNOUT: 'PROFILE_SIGNOUT',
  PROFILE_GET_BY_TYPE: 'PROFILE_GET_BY_TYPE',
  PROFILE_GET_ALL: 'PROFILE_GET_ALL',
};

export function confirmRegisMessage(id) {
  return confirmRegis(id);
}

export function updateProfile(uid, field, value) {
  return db.collection("profile").doc(uid)
    .update({
      [field]: value,
    });
}

export function signoutProfile() {
  return dispatch => {
    dispatch({
      type: types.PROFILE_SIGNOUT,
      payload: {
        result: '',
      }
    });
  }
}

export function getProfile() {
  return (dispatch, getState) => {
    const { auth: { authUser: { uid } } } = getState();
    db.collection("profile").doc(uid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          dispatch({
            type: types.PROFILE_GET,
            payload: {
              result: doc.data(),
            }
          });
        } else {
          dispatch({
            type: types.PROFILE_GET,
            payload: {
              result: null,
            },
          });
        }
      });
  }
}

export function getAllProfile() {
  return dispatch => {
    db.collection("profile")
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.PROFILE_GET_ALL,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.PROFILE_GET_ALL,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getProfileByType(type) {
  return dispatch => {
    db.collection("profile").where('type', '==', type)
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.PROFILE_GET_BY_TYPE,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
              type,
            }
          });
        } else {
          dispatch({
            type: types.PROFILE_GET_BY_TYPE,
            payload: {
              result: [],
              type,
            },
          });
        }
      });
  }
}