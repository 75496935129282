/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styles from './Loading.module.scss';

const Loading = () => {
  return <section className={styles.root}>
    <div className={styles["lds-facebook"]}><div></div><div></div><div></div></div>
  </section >
}

export default Loading
