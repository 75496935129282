import { types } from '../action/eventAction';

export function eventReducer(state = {
  event: [],
  myEvent: [],
  matchEventHistory: [],
  eventSubmit: [],
}, { type, payload }) {
  switch (type) {
    case types.MY_EVENT_GET:
      return {
        ...state,
        myEvent: payload.result,
      };
    case types.EVENT_GET:
      return {
        ...state,
        event: payload.result,
      };
    case types.EVENT_SUBMIT_GET:
      return {
        ...state,
        eventSubmit: payload.result,
      };
    case types.MATCH_EVENT_HISTORY:
      return {
        ...state,
        matchEventHistory: payload.result,
      };
    default: return state;
  }
}

export default {
  eventReducer,
};
