
import moment from 'moment-timezone';
import fire from '../fire';

const db = fire.firestore();

export const types = {
  INVOICE_MY_GET: 'INVOICE_MY_GET',
  INVOICE_ALL_GET: 'INVOICE_ALL_GET',
  INVOICE_BOOK_DATE_RANGE: 'INVOICE_BOOK_DATE_RANGE',
};

export function invoiceOnDateRange(start, end) {
  const start_date = new Date(start);
  const end_date = new Date(end);
  return dispatch => db.collection("invoice")
    .where('date_timestamp', '>=', start_date)
    .where('date_timestamp', '<=', end_date)
    .onSnapshot(doc => {
      if (doc && doc.docs && doc.docs.length) {
        dispatch({
          type: types.INVOICE_BOOK_DATE_RANGE,
          payload: {
            result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
          }
        });
      } else {
        dispatch({
          type: types.INVOICE_BOOK_DATE_RANGE,
          payload: {
            result: [],
          },
        });
      }
    });
}

export function createInvoice(ref, detail = {}) {
  const today = moment().tz('Asia/Bangkok');
  return db.collection("invoice")
    .doc(ref)
    .set({
      ...detail,
      create_date_timestamp: new Date(today.format('YYYY-MM-DD')),
    });
}

export function adminCancelInvoice(ref, callback = () => { }) {
  return (dispatch, getState) => {
    const { location: { allHistory } } = getState();
    const books = allHistory.filter(ah => ah.book_ref === ref)
      .map(ah => ah.id)
    db.collection("invoice")
      .doc(ref)
      .update({
        status: 'cancel',
      })
      .then(() => {
        const bookUpdate = books.map(bs => db.collection("park_history")
          .doc(bs)
          .update({
            status: 'cancel',
          }));
        return Promise.all([...bookUpdate])
      })
      .then(() => callback());
  };
}

export function adminUpdateInvoice(ref, status, callback = () => { }) {
  return (dispatch, getState) => {
    const { location: { allHistory } } = getState();
    const books = allHistory.filter(ah => ah.book_ref === ref)
      .map(ah => ah.id)
    db.collection("invoice")
      .doc(ref)
      .update({
        status,
      })
      .then(() => {
        const bookUpdate = books.map(bs => db.collection("park_history")
          .doc(bs)
          .update({
            status,
          }));
        return Promise.all([...bookUpdate])
      })
      .then(() => callback());
  };
}

export function getAllInvoice() {
  return dispatch => {
    db.collection("invoice")
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.INVOICE_ALL_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.INVOICE_ALL_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getMyInvoice() {
  return (dispatch, getState) => {
    const { auth: { authUser: { uid } } } = getState();
    db.collection("invoice")
      .where('uid', '==', uid)
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.INVOICE_MY_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            },
          });
        } else {
          dispatch({
            type: types.INVOICE_MY_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}