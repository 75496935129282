export const types = {
  SHARED_SET_TITLE: 'SHARED_SET_TITLE',
};

export function setTitle(title) {
  return dispatch => {
    dispatch({
      type: types.SHARED_SET_TITLE,
      payload: {
        result: title,
      },
    });
  }
}