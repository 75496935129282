
import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './reducer';

let preMiddleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  preMiddleware = [...preMiddleware, logger];
}

const reduxDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const middleware = applyMiddleware(...preMiddleware);

const configureStore = () => {
  const store = createStore(reducer, reduxDevTools, middleware);
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducer', () => {
        store.replaceReducer(reducer);
      });
    }
  }
  return store;
};

export default configureStore;