/* eslint-disable no-throw-literal */

import _ from 'lodash';
import moment from 'moment-timezone';
import { BOOK_CANCEL_STATUS, LOCATION_ACTIVE, SLOT_ACTIVE_STATUS } from '../constant';
import fire from '../fire';
import { deleteFile, uploadFile, uploadManyFile } from '../util/fireHelper';

const db = fire.firestore();

export const types = {
  LOCATION_GET: 'LOCATION_GET',
  LOCATION_PARKSLOT_TIME_GET: 'LOCATION_PARKSLOT_TIME_GET',
  LOCATION_PARKSLOT_GET: 'LOCATION_PARKSLOT_GET',
  LOCATION_PARKHISTORY_SPECIFIC_GET: 'LOCATION_PARKHISTORY_GET',
  LOCATION_MY_PARKHISTORY_GET: 'LOCATION_MY_PARKHISTORY_GET',
  LOCATION_ALL_PARKHISTORY_GET: 'LOCATION_ALL_PARKHISTORY_GET',
  LOCATION_BOOK_DATE_RANGE: 'LOCATION_BOOK_DATE_RANGE',
  LOCATION_PARKZONE_GET: 'LOCATION_PARKZONE_GET',
  LOCATION_PARKHISTORY_ZONE_GET: 'LOCATION_PARKHISTORY_ZONE_GET',
};

export function bookOnDateRange(start, end) {
  const start_date = new Date(start);
  const end_date = new Date(end);
  return dispatch => db.collection("park_history")
    .where('date_timestamp', '>=', start_date)
    .where('date_timestamp', '<=', end_date)
    .onSnapshot(doc => {
      if (doc && doc.docs && doc.docs.length) {
        dispatch({
          type: types.LOCATION_BOOK_DATE_RANGE,
          payload: {
            result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
          }
        });
      } else {
        dispatch({
          type: types.LOCATION_BOOK_DATE_RANGE,
          payload: {
            result: [],
          },
        });
      }
    });
}

export function onEditLocationImage(uid, filename, file) {
  const path = `park_location/${uid}`;
  return deleteFile(path, filename)
    .then(() => deleteFile(path, `${filename}-800x800`))
    .then(() => uploadFile(path, filename, file))
}

export function updateParkLocation(id, field, data) {
  return db.collection("park_location")
    .doc(id)
    .update({
      [field]: data,
    });
}

export function updateParkSlot(id, field, data) {
  return db.collection("park_slot")
    .doc(id)
    .update({
      [field]: data,
    });
}

export function updateParkSlotTime(id, field, data) {
  return db.collection("park_time_slot")
    .doc(id)
    .update({
      [field]: data,
    });
}

export function getAllHistory() {
  return dispatch => {
    db.collection("park_history")
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.LOCATION_ALL_PARKHISTORY_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.LOCATION_ALL_PARKHISTORY_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getMyHistory() {
  return (dispatch, getState) => {
    const { auth: { authUser: { uid } } } = getState();
    db.collection("park_history")
      .where('uid', '==', uid)
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.LOCATION_MY_PARKHISTORY_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            },
          });
        } else {
          dispatch({
            type: types.LOCATION_MY_PARKHISTORY_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function book(
  uid, book_ref, park_location_uid, park_slot_uid,
  date, park_slot_time_uids, status
  ) {
  const today = moment().tz('Asia/Bangkok');
  return db.runTransaction(transaction => {
    const bookingProcess = park_slot_time_uids.map(pstu => {
      const bookingRef = db.collection("park_history")
        .doc(`${park_location_uid}_${park_slot_uid}_${date}_${pstu}`);
      return transaction.get(bookingRef).then(rDoc => {
        if (rDoc.exists && rDoc.data().status !== BOOK_CANCEL_STATUS) {
          throw "Booking already exist"
        } else {
          transaction.set(bookingRef, {
            book_ref,
            uid,
            park_location_uid,
            park_slot_uid,
            date,
            park_slot_time_uid: pstu,
            status,
            date_timestamp: new Date(date),
            timestamp: today.valueOf(),
            type: 'pt',
          });
        }
      });
    })
    return Promise.all([...bookingProcess]);
  });
}

export function bookMarket(
  uid, book_ref, park_location_uid, park_zone_uid,
  park_slot_uid, date, status, selectedFoodType,
  endSelectDate = '',
) {
  const today = moment().tz('Asia/Bangkok');
  return db.runTransaction(transaction => {
    const bookingRef = db.collection("park_history")
      .doc(`${park_location_uid}_${park_slot_uid}_${date}_${park_zone_uid}`);
    return transaction.get(bookingRef).then(rDoc => {
      if (rDoc.exists && rDoc.data().status !== BOOK_CANCEL_STATUS) {
        throw "Booking already exist"
      } else {
        transaction.set(bookingRef, {
          book_ref,
          uid,
          park_location_uid,
          park_slot_uid,
          date,
          park_zone_uid,
          status,
          date_timestamp: new Date(date),
          timestamp: today.valueOf(),
          type: 'market',
          selectedFoodType,
          endSelectDate,
        });
      }
    });
  })
}

export function getSpecificParkHistory(park_slot_uid, date, callback = () => { }) {
  return dispatch => {
    db.collection("park_history")
      .where('park_slot_uid', '==', park_slot_uid)
      .where('date', '==', date)
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.LOCATION_PARKHISTORY_SPECIFIC_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            },
          });
        } else {
          dispatch({
            type: types.LOCATION_PARKHISTORY_SPECIFIC_GET,
            payload: {
              result: [],
            },
          });
        }
        callback()
      });
  }
}

export function getParkHistoryOnPage(
  park_location_uid, date, callback = () => { }
) {
  return db.collection("park_history")
    .where('park_location_uid', '==', park_location_uid)
    .where('date', '==', date)
    .onSnapshot(doc => {
      if (doc && doc.docs && doc.docs.length) {
        callback(doc.docs.map(d => ({ id: d.id, ...d.data() })));
      } else {
        callback([]);
      }
    });
}

export function getZoneParkHistory(
  park_zone_uid, date, callback = () => { },
) {
  return dispatch => {
    db.collection("park_history")
      .where('park_zone_uid', '==', park_zone_uid)
      .where('date', '==', date)
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.LOCATION_PARKHISTORY_ZONE_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            },
          });
        } else {
          dispatch({
            type: types.LOCATION_PARKHISTORY_ZONE_GET,
            payload: {
              result: [],
            },
          });
        }
        callback()
      });
  }
}

export function getAllParkSlot() {
  return dispatch => {
    db.collection("park_slot")
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.LOCATION_PARKSLOT_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.LOCATION_PARKSLOT_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getAllParkZone() {
  return dispatch => {
    db.collection("park_zone")
      .onSnapshot(doc => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.LOCATION_PARKZONE_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.LOCATION_PARKZONE_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getAllLocation() {
  return dispatch => {
    db.collection("park_location")
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.LOCATION_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.LOCATION_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getAllParkSlotTime() {
  return dispatch => {
    db.collection("park_time_slot")
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.LOCATION_PARKSLOT_TIME_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.LOCATION_PARKSLOT_TIME_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function createLocation(profile, images, time, onLoadImage, onLoadTime) {
  const mProfile = _.pickBy(profile, p => p !== undefined);
  let shareUid = '';
  return db.collection("park_location").add({
    ...mProfile,
    timestamp: moment().tz('Asia/Bangkok').valueOf(),
    type: 'pt',
    status: LOCATION_ACTIVE,
  })
    .then(({ id }) => {
      shareUid = id;
      const timeSave = [...time].map(t => {
        const { slotName, timeSlot } = t;
        // delete t.search;
        return db.collection("park_slot").add({
          name: slotName,
          park_location_uid: shareUid,
          status: SLOT_ACTIVE_STATUS,
        }).then(slot => {
          const addSlot = timeSlot.map(ts => db.collection("park_time_slot").add({
            index: ts.index,
            time: ts.time,
            status: ts.status,
            tags: ts.tags.map(tg => tg.id),
            park_location_uid: shareUid,
            park_slot_uid: slot.id,
            timestamp: moment().tz('Asia/Bangkok').valueOf(),
          }));
          return Promise.all([...addSlot]);
        });
      })
      return Promise.all([...timeSave]);
    })
    .then(() => {
      onLoadTime(100);
      return Promise.all([
        ...uploadManyFile(`park_location/${shareUid}`, images, onLoadImage),
      ]);
    });
}