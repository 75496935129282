import { types } from '../action/profileAction';

export function profileReducer(state = {
  profile: null,
  truck: [],
  all: [],
}, { type, payload }) {
  switch (type) {
    case types.PROFILE_GET:
      return {
        ...state,
        profile: payload.result,
      };
    case types.PROFILE_GET_BY_TYPE:
      return {
        ...state,
        [payload.type]: payload.result,
      };
    case types.PROFILE_GET_ALL:
      return {
        ...state,
        all: payload.result,
      };
    case types.PROFILE_SIGNOUT:
      return {
        ...state,
        profile: null,
      };
    default: return state;
  }
}

export default {
  profileReducer,
};