import moment from 'moment-timezone';
import { MAXCARD_ID_SEARCH_TYPE, MAXCARD_TEL_SEARCH_TYPE, PRODUCT_CODE_PROD } from '../constant';
import fire from '../fire';

const db = fire.firestore();
const functions = fire.functions();
const getProfileByMaxCard = functions.httpsCallable('getProfileByMaxCard');
const getProfileByMobile = functions.httpsCallable('getProfileByMobile');
const rewardPoint = functions.httpsCallable('rewardPoint');

export const types = {
  MAXCARD_HISTORY_GET: 'MAXCARD_HISTORY_GET',
  MAXCARD_MY_HISTORY_GET: 'MAXCARD_MY_HISTORY_GET',
};

export function getProfileBy(type, data) {
  switch (type) {
    case MAXCARD_ID_SEARCH_TYPE:
      return getProfileByMaxCard({
        number: data,
      });
    case MAXCARD_TEL_SEARCH_TYPE:
      return getProfileByMobile({
        number: data,
      });
    default: return;
  }
}

export function getAllMaxcardHistory() {
  return (dispatch) => {
    db.collection("maxcard_history")
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.MAXCARD_HISTORY_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.MAXCARD_HISTORY_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getMyMaxcardHistory() {
  return (dispatch, getState) => {
    const { auth: { authUser: { uid } } } = getState();
    db.collection("maxcard_history")
      .where('shopId', '==', uid)
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.MAXCARD_MY_HISTORY_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.MAXCARD_MY_HISTORY_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function storeMaxCardHistory(item, callback = () => { }) {
  const { cardNo, price } = item;
  const PRODUCT_QUAN = "1.0";
  const PRODUCT_CODE = PRODUCT_CODE_PROD;
  const status = {};
  return (dispatch, getState) => {
    const { auth: { authUser: { uid } },
      profile: { profile: { mid, tid } }
    } = getState();
    rewardPoint({
      mid,
      tid,
      cardNo,
      PRODUCT_CODE,
      PRODUCT_PRICE: price,
      PRODUCT_QUAN,
    }).then(result => {
      const { data: { TRANS_ID }, success, message } = result.data;
      status.transId = TRANS_ID;
      status.status = success;
      status.message = message;
      if (success) {
        return db.collection("maxcard_history")
          .add({
            mid,
            tid,
            price,
            cardNo,
            shopId: uid,
            status: 'success',
            transId: TRANS_ID,
            productQuan: PRODUCT_QUAN,
            productCode: PRODUCT_CODE,
            timestamp: moment().tz('Asia/Bangkok').valueOf(),
          });
      } else {
        return db.collection("maxcard_history_err")
          .add({
            mid,
            tid,
            price,
            cardNo,
            transId: 0,
            shopId: uid,
            status: 'fail',
            productQuan: PRODUCT_QUAN,
            productCode: PRODUCT_CODE,
            timestamp: moment().tz('Asia/Bangkok').valueOf(),
          });
      }
    }).then(() => {
      callback(status);
    });
  }
}

