import { types } from '../action/orgAction';

export function orgReducer(state = {
  orgs: [],
}, { type, payload }) {
  switch (type) {
    case types.ORG_GET:
      return {
        ...state,
        orgs: payload.result,
      };
    default: return state;
  }
}

export default {
  orgReducer,
};
