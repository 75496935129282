import { types } from '../action/maxCardAction';

export function maxCardReducer(state = {
  all: [],
  my: [],
}, { type, payload }) {
  switch (type) {
    case types.MAXCARD_HISTORY_GET:
      return {
        ...state,
        all: payload.result,
      };
    case types.MAXCARD_MY_HISTORY_GET:
      return {
        ...state,
        my: payload.result,
      };
    default: return state;
  }
}

export default {
  maxCardReducer,
};