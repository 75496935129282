import { types } from '../action/locationAction';

export function locationReducer(state = {
  timeSlot: [],
  parkSlot: [],
  parkZone: [],
  all: [],
  selectedHistory: [],
  selectedMarketHistory: [],
  myHistory: [],
  allHistory: [],
  bookRange: [],
}, { type, payload }) {
  switch (type) {
    case types.LOCATION_BOOK_DATE_RANGE:
      return {
        ...state,
        bookRange: payload.result,
      };
    case types.LOCATION_GET:
      return {
        ...state,
        all: payload.result,
      };
    case types.LOCATION_PARKSLOT_TIME_GET:
      return {
        ...state,
        timeSlot: payload.result,
      };
    case types.LOCATION_PARKSLOT_GET:
      return {
        ...state,
        parkSlot: payload.result,
      };
    case types.LOCATION_PARKZONE_GET:
      return {
        ...state,
        parkZone: payload.result,
      };
    case types.LOCATION_PARKHISTORY_SPECIFIC_GET:
      return {
        ...state,
        selectedHistory: payload.result,
      };
    case types.LOCATION_PARKHISTORY_ZONE_GET:
      return {
        ...state,
        selectedMarketHistory: payload.result,
      };
    case types.LOCATION_MY_PARKHISTORY_GET:
      return {
        ...state,
        myHistory: payload.result,
      };
    case types.LOCATION_ALL_PARKHISTORY_GET:
      return {
        ...state,
        allHistory: payload.result,
      };
    default: return state;
  }
}

export default {
  locationReducer,
};