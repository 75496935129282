
import fire from '../fire';

export function deleteFile(path, filename) {
  return fire.storage().ref().child(`${path}/${filename}`).delete();
}

export function uploadFile(path, filename, file) {
  return fire.storage().ref().child(`${path}/${filename}`).put(file);
}

export function getFile(path, filename) {
  return fire.storage().ref().child(`${path}/${filename}`).getDownloadURL();
}

export function uploadManyFile(path, files, onEachLoadReport) {
  const toPromiseUploadArray = (array, path) => Object.keys(array)
    .map(key => uploadFile(path, array[key].name, array[key].file));
  const onLoadReport = (ul, callback) => ul
    .on('state_changed', snapshot => callback(snapshot.bytesTransferred / snapshot.totalBytes * 100));
  const uploadImgs = toPromiseUploadArray(files, path);
  // track
  uploadImgs.map(ul => onLoadReport(ul, onEachLoadReport));
  return uploadImgs;
}

export default {
  uploadFile,
  getFile,
  deleteFile,
};