import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.module.scss';
import App from './page/App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import './_theme.scss';

const store = configureStore();

const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL}>
        <Component />
      </Router>
    </Provider>
    ,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./page/App', () => {
    const NextApp = require('./page/App').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
