
import moment from 'moment-timezone';
import { EVENT_ACTIVE_STATUS, EVENT_ATTEND, EVENT_ORG_CON, EVENT_SUBMITED, EVENT_WAIT_ORG_CONFIRM } from '../constant';
import fire from '../fire';
import { regisEventNoti } from '../util/noti';

const db = fire.firestore();
const functions = fire.functions('asia-northeast1');
const LineBotEventSelectedShop = functions.httpsCallable('LineBot_Event_SelectedShop');

export const types = {
  EVENT_GET: 'EVENT_GET',
  MY_EVENT_GET: 'MY_EVENT_GET',
  ORG_MY_EVENT_GET: 'ORG_MY_EVENT_GET',
  MATCH_EVENT_HISTORY: 'MATCH_EVENT_HISTORY',
  EVENT_SUBMIT_GET: 'EVENT_SUBMIT_GET',
};

export function getMyEventHistory() {
  return (dispatch, getState) => {
    const { auth: { authUser: { uid } } } = getState();
    db.collection("event_history")
      .where('truckId', '==', uid)
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.MY_EVENT_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.MY_EVENT_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getMatchEventHistory(eventId) {
  return dispatch => {
    db.collection("event_history")
      .where('eventId', '==', eventId)
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.MATCH_EVENT_HISTORY,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.MATCH_EVENT_HISTORY,
            payload: {
              result: [],
            },
          });
        }
      });
  }

}

export function orgGetMyEvent() {
  return (dispatch, getState) => {
    const { auth: { authUser: { uid } } } = getState();
    db.collection("event")
      .where('orgId', '==', uid)
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.MY_EVENT_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.MY_EVENT_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getAllEvent() {
  return dispatch => {
    db.collection("event")
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.EVENT_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.EVENT_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getSubmitEvent() {
  return dispatch => {
    db.collection("event")
      .where('status', '==', EVENT_SUBMITED)
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.EVENT_SUBMIT_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.EVENT_SUBMIT_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function getActiveEvent() {
  return dispatch => {
    db.collection("event")
      .where('status', '==', EVENT_ACTIVE_STATUS)
      .onSnapshot((doc) => {
        if (doc && doc.docs && doc.docs.length) {
          dispatch({
            type: types.EVENT_GET,
            payload: {
              result: doc.docs.map(d => ({ id: d.id, ...d.data() })),
            }
          });
        } else {
          dispatch({
            type: types.EVENT_GET,
            payload: {
              result: [],
            },
          });
        }
      });
  }
}

export function createEvent(detail) {
  return db.collection("event").add({
    ...detail,
    str_timestamp: moment(detail.startDate).tz('Asia/Bangkok').valueOf(),
    end_timestamp: moment(detail.endDate).tz('Asia/Bangkok').valueOf(),
    timestamp: moment().tz('Asia/Bangkok').valueOf(),
    status: EVENT_ACTIVE_STATUS,
  });
}

export function regisEvent(detail) {
  return (dispatch, getState) => {
    const { auth: { authUser: { uid } },
      profile: { profile: { shopName, tel } },
      event: { event } } = getState();
    const { title } = event.find(ev => ev.id === detail.eventId) || {};
    regisEventNoti(shopName, tel, title);
    db.collection("event_history").add({
      ...detail,
      truckId: uid,
      timestamp: moment().tz('Asia/Bangkok').valueOf(),
      status: EVENT_ATTEND,
    });
  };
}

export function updateEventHistory(id, field, data) {
  return db.collection("event_history")
    .doc(id)
    .update({
      [field]: data,
    });
}

export function updateEvent(id, field, data) {
  return db.collection("event")
    .doc(id)
    .update({
      [field]: data,
    });
}

export function submitEvent(id, callback = () => { }) {
  return (dispatch, getState) => {
    const { event: { matchEventHistory, myEvent }, profile: { truck } } = getState();
    const thisEvent = myEvent.find(me => me.id === id) || {};
    const selected = matchEventHistory
      .filter(meh => meh.status === EVENT_WAIT_ORG_CONFIRM)
    const onlySelectedTruckId = selected
      .map(meh => meh.truckId);
    const lineIds = truck
      .filter(tk => onlySelectedTruckId.includes(tk.id))
      .map(tk => tk.line_id)
      .filter(tk => (tk !== null) && (tk !== undefined))
    // update history
    const updateSelectedProm = selected.map(std => updateEventHistory(std.id, 'status', EVENT_ORG_CON));
    Promise.all(updateSelectedProm)
      .then(async () => {
        // send line bot
        LineBotEventSelectedShop(
          {
            ...thisEvent,
            shopAmount: selected.length,
            ids: lineIds,
          }
        );
        // update event
        updateEvent(id, 'selectStatus', EVENT_SUBMITED)
          .then(() => {
            return updateEvent(id, 'selectedAmount', selected.length);
          })
          .then(() => callback());
      })
      .catch(() => {

      });
  }
}